const isBlogPage = window.location.href.includes('/blogs/');

const getProductIdInputs = () => {
  return window.document.querySelectorAll(
    '.shopify-section--featured-product .product input[name="product-id"]',
  );
};

const getFitmentIcon = (fits) => {
  return fits === 'yes'
    ? `
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
        <rect width="18" height="18" x="1" y="1" fill="#48C11E" stroke="#48C11E" stroke-width="2" rx="9"></rect>
        <path stroke="#fff" stroke-linecap="square" stroke-linejoin="round" stroke-width="2" d="m6.155 10.585 2.354 2.353 6.015-6.015"></path>
      </svg>`
    : `
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="18" height="18" rx="9" fill="#FF0000" stroke="#FF0000" stroke-width="2" />
        <g clip-path="url(#clip0_819_52756)">
          <path d="M14.993 5.07788L4.99304 15.0779" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          <path d="M4.99304 5.07788L14.993 15.0779" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" />
        </g>
        <defs><clip-path id="clip0_819_52756"><rect width="10" height="10" fill="white" transform="translate(5 5)" /></clip-path></defs>
      </svg>`;
};

const getVerifyFitmentLabelHtml = (fits, vehicleLabel) => {
  const icon = getFitmentIcon(fits);
  const title =
    fits === 'yes' ? 'This product fits your vehicle:&nbsp;' : 'This product doesn’t fit your vehicle:&nbsp;';

  return `
    <div class="cm_verify-fitment_blog cm_verify-fitment-label-container cm_hide-button">
      <div class="cm_vehicle-widget cm_vehicle-widget__verify-fitment">
        <div class="cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__${fits}">
          <div class="cm_verify-fitment_icon">${icon}</div>
          <div class="cm_verify-fitment_fitment">
            <div class="cm_verify-fitment_title">${title}</div>
            <div class="cm_verify-fitment_body">
              <span class="cm_verify-fitment_vehicle">${vehicleLabel}</span>
              <button onclick="window.Convermax.openDialog('VehicleWidgetDialog')" class="cm_verify-fitment_button">
                Change Vehicle
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>`;
};

const addVerifyFitmentLabel = (fits, parentElement, vehicleLabel) => {
  const labelHtml = getVerifyFitmentLabelHtml(fits, vehicleLabel);
  const target = parentElement?.previousElementSibling || parentElement;

  if (!target) {
    return;
  }

  const existingLabel = target.previousElementSibling;
  if (existingLabel?.classList.contains('cm_verify-fitment-label-container')) {
    existingLabel.remove();
  }

  target.insertAdjacentHTML('beforebegin', labelHtml);
};

const openVehicleDialog = (e) => {
  e.preventDefault();
  window.Convermax.openDialog('VehicleWidgetDialog');
};

const removeOpenVehicleDialogListeners = (productId) => {
  const productInputs = document.querySelectorAll(
    `.shopify-section--featured-product .product input[name="product-id"][value="${productId}"]`,
  );

  productInputs.forEach((input) => {
    const buttonContainer = input.closest('.product')?.querySelector('.product-info__button');
    if (buttonContainer) {
      buttonContainer.removeEventListener('click', openVehicleDialog);
    }
  });
};

const verifyProduct = (productId, vehicle, parentElement) => {
  const vehicleLabel = Object.values(vehicle).join(' ');

  window.Convermax.verifyFitmentAsync(productId, vehicle)
    .then((fits) => {
      if (['yes', 'no'].includes(fits)) {
        addVerifyFitmentLabel(fits, parentElement, vehicleLabel);
      }
    })
    .catch(() => {
      removeOpenVehicleDialogListeners(productId);
      addVerifyFitmentLabel('yes', parentElement, vehicleLabel); // handle master products
    });
};

const updateVerifyFitmentLabels = (productIdInputs, vehicle) => {
  productIdInputs.forEach(({ defaultValue: productId, parentElement }) => {
    verifyProduct(productId, vehicle, parentElement);
  });
};

export const onVehicleSelected = (vehicle) => {
  if (!isBlogPage) {
    return;
  }

  const inputs = getProductIdInputs();
  if (inputs.length) {
    updateVerifyFitmentLabels(inputs, vehicle);
  }
};

export const AfterInit = () => {
  if (!isBlogPage) {
    return;
  }

  const productIdInputs = getProductIdInputs();
  const vehicle = window.Convermax.getVehicle();

  if (Object.keys(vehicle).length && productIdInputs.length) {
    updateVerifyFitmentLabels(productIdInputs, vehicle);
  }

  const productButtons = document.querySelectorAll('main .product-info__button');

  productButtons.forEach((button) => {
    button.addEventListener('click', openVehicleDialog);
  });
};
